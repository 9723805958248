import "@res/landrick/css/materialdesignicons.min.css";
import '@res/css/style.scss'
import "@res/landrick/css/colors/default.css";
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Provider } from 'react-redux'
import { store } from '@redux/store'

function NextApp({ Component, pageProps }: AppProps)
{
    return (
        <Provider store={store}>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="title" content="LearnLogics"></meta>
                <title>LearnLogics</title>
            </Head>
            <Component {...pageProps} />
        </Provider>
    )
}

export default NextApp
