import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface UserState
{
    isUserLoggedIn: boolean;
}

const initialState: UserState = {
    isUserLoggedIn: false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIsUserLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isUserLoggedIn = action.payload;
        }
    }
});

export const { setIsUserLoggedIn } = userSlice.actions;

export default userSlice.reducer;