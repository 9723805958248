export enum Environment
{
    Test = 'test',
    Development = 'development',
    Production = 'production'
}

interface Config
{
    /** The version of the app. */
    version: string;
    /** The app environment. */
    environment: Environment;
    /** The URL of the this Next.js website. */
    appUrl: string;
    /** The GraphQL API URL.  */
    apiUrl: string;
    /** Is the CDN enabled? */
    $isCdnEnabled: boolean;
    /** The base URL of the AWS CDN. */
    $cdnBaseUrl: string;
    /** The base URL for the /public directory. */
    $publicDirBaseUrl: string;
    /** A list of available languages. */
    languages: AppLanguage[]
    /** The default languge of the app. */
    defaultLanguage: AppLanguage;
    /** The default languge of the admin interface. */
    defaultAdminLanguage: AppLanguage;
    /** The Google Analytics tracking ID. */
    googleAnalyticsId: string;
    /** The Facebook Pixel tracking ID. */
    facebookPixelId: string;
    /** The Facebook App ID. */
    facebookAppId: string;
    /** The Google API client ID. https://developers.google.com/identity/gsi/web/guides/get-google-api-clientid */
    googleApiClientId: string;
    /** Paylike public key. */
    paylikePublicKey: string,
}

let environment: Environment = process.env.NODE_ENV as Environment;
let isProduction = environment == Environment.Production;
let isCdnEnabled = parseInt(process.env.NEXT_PUBLIC_CDN_ENABLED as string) == 1;
let languages = [
    { code: 'ro', name: 'Română', locale: 'ro_RO' },
];

let config: Config =
{
    version: process.env.NEXT_PUBLIC_VERSION as string,
    environment: environment,
    appUrl: process.env.NEXT_PUBLIC_APP_URL as string,
    apiUrl: process.env.NEXT_PUBLIC_API_URL as string,
    $isCdnEnabled: isCdnEnabled,
    $cdnBaseUrl: process.env.NEXT_PUBLIC_CDN_BASE_URL as string,
    $publicDirBaseUrl: (isProduction && isCdnEnabled ? process.env.NEXT_PUBLIC_CDN_BASE_URL : process.env.NEXT_PUBLIC_APP_URL) as string,
    languages: languages,
    defaultLanguage: languages.find(l => l.code == 'ro')!,
    defaultAdminLanguage: languages.find(l => l.code == 'ro')!,
    googleAnalyticsId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID as string,
    facebookPixelId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string,
    facebookAppId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string,
    googleApiClientId: process.env.NEXT_PUBLIC_GOOGLE_API_CLIENT_ID as string,
    paylikePublicKey: process.env.NEXT_PUBLIC_PAYLIKE_PUBLIC_KEY as string
}

export type AppLanguage = {
    /** Language code. Eg: en, hu, ro */
    code: string, 
    /** The name of the language in the given language. */
    name: string,
    /** A language local name. Eg: en_US */
    locale: string,
};

export default config;